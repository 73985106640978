import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import queryString from 'query-string';
import { mixed, object, string } from 'yup';

import { ModalEnum } from '../../ModalEnum';
import { changePassword } from '../../model/UserAuthorization';
import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import { openModal } from '../../store/app';
import { flashMessageError, flashMessageSuccess } from '../../store/session';
import { FormGroupField } from '../Form/FormGroup';
import FormikSubmitButton from '../Form/SubmitButton';
import T, { useTranslation } from '../Translate';

// Schema A: Letters and numbers
const schemaA = string()
  .matches(/[A-Z]/, 'modals.sign.validate.password')
  .matches(/[0-9]/, 'modals.sign.validate.password')
  .matches(/[^a-zA-Z]/, 'modals.sign.validate.password')
  .min(8, 'modals.sign.password.format')
  .required('sign.resetPassword.note');

// Schema B: Letters and special characters without numbers
const schemaB = string()
  .matches(/[A-Z]/, 'modals.sign.validate.password')
  .matches(/[^a-zA-Z\d\s:]/, 'modals.sign.validate.password')
  .min(8, 'modals.sign.password.format')
  .required('sign.resetPassword.note');

const combinedSchema = mixed().test({
  name: 'either-letters-numbers-or-letters-special-characters',
  test: value => schemaA.isValidSync(value) || schemaB.isValidSync(value),
  message: 'sign.resetPassword.note',
});

export default function ResetPassword() {
  const dispatch = useDispatch();
  const { location, navigate } = useRouteContext();
  const { t } = useTranslation();
  const searchParams: { hash?: string } = queryString.parse(location.search);

  const formik = useFormik({
    validationSchema: object({
      password: combinedSchema,
      password_confirm: combinedSchema,
    }),
    initialValues: {
      password: '',
      password_confirm: '',
    },
    async onSubmit({ password, password_confirm }) {
      if (password !== password_confirm) {
        dispatch(flashMessageError('sign.resetPassword.mismatch'));
        return;
      }

      try {
        await changePassword(searchParams.hash, password, password_confirm);
        navigate(RouteEnum.HOMEPAGE);
        dispatch(flashMessageSuccess('sign.resetPassword.success'));
        dispatch(openModal(ModalEnum.SIGN_IN));
      } catch (err) {
        dispatch(flashMessageError(err.message));
      }
    },
  });

  return (
    <main className="main-container">
      <Helmet>
        <title>{`${t('sign.resetPassword.title')} | HoppyGo.com`}</title>
        <meta content="noindex" name="robots" />
      </Helmet>

      <section className="container container--top container--sm">
        <h1 className="display-2 my-3 text-center">
          <T id="sign.resetPassword.title" />
        </h1>
        <div className="font-size-lg mb-1 text-muted text-center">
          <T id="sign.resetPassword.text" />
        </div>
        <div className="font-size-sm text-muted text-center">
          <T id="sign.resetPassword.note" />
        </div>
      </section>

      <FormikProvider value={formik}>
        <Form className="container container--sm mt-5">
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <FormGroupField
                label="sign.resetPassword.password"
                name="password"
                placeholder={t('sign.resetPassword.password')}
                required
                type="password"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <FormGroupField
                label="sign.resetPassword.passwordConfirm"
                name="password_confirm"
                placeholder={t('sign.resetPassword.passwordConfirm')}
                required
                type="password"
              />
            </div>
          </div>
          <div className="row mb-5 pb-5">
            <div className="col-sm-8 offset-sm-2">
              <FormikSubmitButton className="btn-lg w-100">
                <T id="sign.resetPassword.button" />
              </FormikSubmitButton>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </main>
  );
}
