import React from 'react';

import { AppState } from '../../services/AppState';
import PageLayout from '../../views/Layout/Page';
import ResetPassword from '../../views/User/ResetPassword';

export default function SignResetPasswordPage() {
  return (
    <PageLayout pageview="change_password" requiredState={AppState.LOADING}>
      <ResetPassword />
    </PageLayout>
  );
}
